<template>
  <v-row
    v-if="gc_pageLoaded"
    class="align-self-stretch align-content-start white c-layout"
  >

    <!--  선택한 게시글  -->
    <v-col
      v-if="article"
      class="px-6"
      cols="12"
    >
      <notice-article
        @goToList="goToList"
        :item="article"
      />
    </v-col>

    <!--  게시판 리스트  -->
    <v-col
      v-show="!article"
      class="px-6"
      cols="12"
    >
      <notice-board
        @setArticle="setArticle"
        :list="list"
        :paging="paging"
      />
    </v-col>

    <!--  게시판 페이징  -->
    <v-col
      v-show="!article"
      class="px-6 text-center"
      cols="12"
    >
      <pagination
        @setPage="setPage"
        :paging="paging"
      />
    </v-col>

  </v-row>
</template>

<script>
import NoticeBoard from "../components/notice/NoticeBoard"
import Pagination from "../components/notice/Pagination"
import NoticeArticle from "../components/notice/NoticeArticle"

export default {
  name: "Notice",

  mounted() {
    this.api_getNotice()
  },

  components: {
    NoticeBoard,
    Pagination,
    NoticeArticle
  },

  data: () => ({
    // 페이징 객체
    paging: {
      cur: 1,
      max: 15,
      limit: 10,
      total: 0
    },

    // 현재 보여줘야 할 공지사항 정보 객체, 없을 경우 null
    article: null,

    // 공지사항 리스트
    list: []
  }),

  watch: {
    'paging.cur'(val) {
      this.paging.cur = val
      this.$store.state.pageLoaded = false
      this.api_getNotice()
    },

    '$store.state.refreshNotice'() {
      if (this.paging.cur !== 1) {
        this.paging.cur = 1
      }
      else if (this.article) {
        this.article = null
      }
    }
  },

  methods: {
    // 현재 페이지 세팅
    setPage(num) {
      this.paging.cur = num
    },

    // 공지사항 본문 보이기 세팅
    setArticle(article) {
      this.article = article
    },

    // 공지사항 내용에서 리스트로 돌아가기
    goToList() {
      this.article = null
    }
  }
}
</script>

<style lang="sass" scoped>
</style>