<template>
  <v-pagination
    v-model="page"
    color="cyan"
    :length="paging.max || 1"
    :total-visible="7"
  />
</template>

<script>
export default {
  name: "Pagination",

  props: {
    paging: {
      type: Object,
      default: () => ({
        cur: 1,
        max: 0,
        limit: 0
      })
    }
  },

  data: () => ({
    page: 1
  }),

  watch: {
    page(val) {
      this.$emit('setPage', val)
    }
  }
}
</script>

<style scoped>

</style>