<template>
  <v-simple-table class="rounded-0">
    <template v-slot:default>

      <!--  게시판 헤더  -->
      <thead>
      <tr>
        <th
          class="text-center"
          width="60"
        >
          No.
        </th>
        <th class="text-center">
          Title
        </th>
        <th
          class="text-center"
          width="120"
        >
          Date
        </th>
      </tr>
      </thead>

      <!--  게시판 리스트  -->
      <tbody v-if="list.length">
      <tr
        v-for="(item, i) in list"
        :key="'notice-' + i"
      >

        <!--  게시글 번호  -->
        <td class="text-center">{{ numbering(i) }}</td>

        <!--  제목  -->
        <td class="d-flex align-center pa-0">
          <v-btn
            @click="$emit('setArticle', item)"
            class="d-flex black--text text-left c-tt-none"
            plain
          >
            <span class="text-truncate c-no-btn-text c-mx-width-50vw">
              {{ item[`title${[gc_lang_prefix]}`] }}
              <v-icon
                v-if="item[`file${gc_lang_prefix}`]"
                color="cyan"
                dense
              >mdi-paperclip</v-icon>
            </span>
          </v-btn>
        </td>

        <!--  작성일  -->
        <td class="text-center c-ws-pre">{{ item.created_at.replace(/\s.*/, '') }}</td>

      </tr>
      </tbody>

      <!--  게시글 결과 없음  -->
      <tbody v-else>
      <tr>
        <td
          class="pa-16 text-center c-ws-pre"
          colspan="3"
        >
          {{ gc_langText.notice.text.noResult[gc_lang] }}
        </td>
      </tr>
      </tbody>

    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "NoticeBoard",

  props: {
    list: {
      type: Array,
      default: () => ([])
    },

    paging: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    numbering(i) {
      const a = (this.paging.cur - 1) * this.paging.limit
      const b = this.paging.total - a

      return b - i
    }
  }
}
</script>

<style lang="sass" scoped>
.c-mx-width-50vw
  max-width: 976px
  width: 75vw
  display: inline-block
.c-no-btn-text
  font-weight: normal
  letter-spacing: normal
  text-indent: unset
</style>