<template>
  <v-card flat>

    <!--  게시글 제목  -->
    <v-card-title class="px-0">
      <h1 class="d-flex flex-column">
        <span class="text-h6 font-weight-bold">
          {{ item[`title${gc_lang_prefix}`] }}
        </span>
        <time
          class="mt-3 subtitle-1"
          datetime="2021-02-01"
        >{{ item.updated_at.replace(/\s.*/, '') }}</time>
      </h1>
    </v-card-title>

    <v-divider/>

    <!--  게시글 본문  -->
    <v-card-text
      class="px-0 c-notice-content"
      v-html="item[`contents${gc_lang_prefix}`]"
    />

    <!--  게시글 첨부파일  -->
    <v-card-text
      v-if="fileName"
      class="px-0"
    >
      <v-icon>mdi-paperclip</v-icon>{{ gc_langText.notice.text.attachments[gc_lang] }}:
      <v-btn
        class="c-tt-none"
        color="blue"
        :href="item[`file${gc_lang_prefix}`]"
        download
        plain
      >
        {{ fileName }}
      </v-btn>
    </v-card-text>

    <v-divider/>

    <!--  게시판 리스트 돌아가기  -->
    <v-card-actions class="px-0">
      <v-btn
        @click="$emit('goToList')"
        depressed
        tile
      >
        <v-icon>mdi-view-list</v-icon>
        {{ gc_langText.notice.text.list[gc_lang] }}
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "NoticeArticle",

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    // 첨부파일 파일명
    fileName() {
      const path = this.item[`file${this.gc_lang_prefix}`]
      const name = path.match(/\/[^/]+$/)

      // 첨부파일이 없거나 잘못된 경우 null 반환
      return name && name[0]
    }
  }
}
</script>

<style scoped>
</style>